import React from 'react';
import { graphql } from 'gatsby';
import GameCreationRoot from '../../components/game-creation-root';
import BeUltimatumReceiverForm from '../../components/game-forms/be-ultimatum-receiver-form';

const UltimatumReceiverPage = (props) => {
	return(
        <GameCreationRoot title={"Ultimatum game (Receiver)"}>
            <BeUltimatumReceiverForm />
        </GameCreationRoot>
	);
}

export default UltimatumReceiverPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "side_bar", "games", "form_errors", "experiment"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;