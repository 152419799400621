import React from 'react';
import {
    Alert,
	Box,
	Button,
	LinearProgress,
} from '@mui/material';
import { Formik, Form } from 'formik';
import Layout from '../layout';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { Timestamp, } from "firebase/firestore";

const formContainer = {
	width: '100%',
	marginTop: '32px',
};

const UltimatumerUserForm = (props) => {
    const [receivedBid, setReceivedBid] = React.useState(false);
    const [decission, setDecission] = React.useState(null);
    const [moneySent, setMoneySent] = React.useState(null);

    const decideProposal = (value) => {
        setDecission(value);

        return setReceivedBid(true);
    };

    React.useEffect(() => {
        setMoneySent(props.isRandom ? Math.floor(Math.random() * props.value) : props.moneyToDivide);
    }, [props.isRandom, props.moneyToDivide])

	return(
        <Layout>
            {moneySent ?
                <Formik
                    initialValues = {{
                        UGs_Money_sent: moneySent,
                        UGs_COST: props.value,
                        UGs_INCREMENT: props.increment,
                        UGs_Answer_receiver: null,
                        start_tm: Timestamp.fromDate(new Date()),
                    }}
                    validateOnChange={false}
                    validateOnBlur={false}
                    style={formContainer}
                    onSubmit={async (values, actions) => { 
                        values.UGs_Answer_receiver = decission ? 'Yes' : 'No';
                        await props.handleSubmit(values)
                        actions.setSubmitting = false;
                    }}
                >
                    {({values, submitForm, isSubmitting}) => (
                        <Form>
                            {decission !== null ?
                                <Box>
                                    <Alert variant="outlined" severity="info"><Trans>Your response was sent to the other participant. You may continue with the experiment</Trans></Alert>
                                </Box>
                                :
                                <Alert variant="outlined" severity="info"><Trans>The proposal the other participant sent is:</Trans> {moneySent}{props.currency}. <Trans>Will you accept or reject the offer?</Trans></Alert>
                            }
                            {isSubmitting && <LinearProgress />}
                            {receivedBid ?
                                <Box margin={1}>
                                    <Button
                                        sx={{m: 1}}
                                        variant="contained"
                                        size="large"
                                        color="primary"
                                        disabled={isSubmitting}
                                        onClick={submitForm}
                                    >
                                        <Trans>Continue</Trans>
                                    </Button>
                                </Box>
                                :
                                <Box margin={1} textAlign={'center'}>
                                    <Button
                                        sx={{m: 1}}
                                        variant="contained"
                                        size="large"
                                        color="success"
                                        disabled={isSubmitting}
                                        onClick={() => { decideProposal(true) }}
                                    >
                                        <Trans>Accept proposal</Trans>
                                    </Button>
                                    <Button
                                        sx={{m: 1}}
                                        variant="contained"
                                        size="large"
                                        color="error"
                                        disabled={isSubmitting}
                                        onClick={() => { decideProposal(false) }}
                                    >
                                        <Trans>Reject proposal</Trans>
                                    </Button>
                                </Box>
                            }
                        </Form>
                    )}
                </Formik>
                :
                <></>
            }
        </Layout>
	);
}

export default UltimatumerUserForm;